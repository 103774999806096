import React from 'react';
import { Link } from 'gatsby';
import Seo from '../components/SEO';

import FourOFour from '../svg/fourOfour.svg';
import useSound from 'use-sound';
import Switch from '../sounds/switch.mp3';

function FourOFourPage({ location }) {
  const showSuccessScreen = location.state && location.state.orderId;
  const [pop] = useSound(Switch);

  if (!showSuccessScreen)
    return (
      <React.Fragment>
        <Seo pageTitle="404" />
        <div className="mt-16 mb-3 md:mt-20 lg:mt-12">
          <div className="flex flex-col items-center py-8 text-primary">
            <FourOFour className="w-5/6 lg:4/5" />
          </div>
          <h1 className="mb-3 text-4xl font-bold text-center md:text-5xl text-primary">
            hmm...
            <br />
            somethings a miss
          </h1>
        </div>
        <p className="w-4/6 py-4 pb-16 m-auto text-base text-center md:w-3/6 text-primary">
          this page has been renamed, removed or never existed. either way, its
          no good to us. lets go back home
        </p>
        <div className="flex flex-col justify-center mx-16 my-16 lg:mx-64">
          <Link className="secondary-btn" to="/" onClick={pop}>
            <b>take me home</b>
          </Link>
        </div>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <Seo pageTitle="Thank you for your order" />
      <div className="mb-6">
        <h1 className="mb-3 text-3xl font-bold md:text-6xl text-primary">
          thank you!
        </h1>

        <hr className="w-10 border-b border-primary" />
      </div>

      <p>
        please take note of your order reference for your records:{' '}
        <span className="font-semibold text-primary">
          {location.state.orderId}
        </span>
      </p>
    </React.Fragment>
  );
}

export default FourOFourPage;
